import request from "@/utils/request"

export default class ApiPublic {
  // 上传图片
  static uploadFile(data) {
    return request({
      url: "api/upload/image",
      method: "POST",
      isTranslate: false,
      data
    })
  }
  // 上传图片关联用户数据
  static uploadFileByUser(data) {
    return request({
      url: "api/upload/user_image",
      method: "POST",
      isTranslate: false,
      data
    })
  }
  // 获取paypal 令牌
  static getPayPal(params) {
    return request({
      url: "api/v2/braintree/getClientToken",
      method: "GET",
      isTranslate: false,
      params
    })
  }
}
