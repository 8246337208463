import request from "@/utils/request";

export default class ApiPurse{
  /**
   * @description 获取账单记录
   * */
  static getBillingRecords(params){
    return request({
      url: "api/spread/commission/" + params.type,
      method: "GET",
      params
    })
  }
  /**
   * @description 获取充值套餐
   * */
  static getTopUpPackage(params){
    return request({
      url: "api/recharge/recharge_info",
      method: "GET",
      params
    })
  }

  /**
   * @description 充值接口
   *
   * */
  static topUpBalance(data){
    return request({
      url: "api/recharge/recharge",
      method: "POST",
      data
    })
  }
}
