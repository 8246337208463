import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-ed4cfc9c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "uni-tips-content"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "content"
};
var _hoisted_4 = {
  class: "modal-footer flex-center mt40"
};
import { ref, computed } from "vue";
export default {
  __name: 'index',
  emits: ["confirm", "close", "update:show"],
  setup: function setup(__props, _ref) {
    var expose = _ref.expose,
      emits = _ref.emit;
    var showModal = ref(false);
    var title = ref("");
    var content = ref("");
    var cancelText = ref(window.$t("myOrder.back"));
    var confirmText = ref(window.$t("myOrder.confirm"));
    var open = function open(params) {
      showModal.value = true;
      title.value = params.title ? params.title : "";
      content.value = params.content ? params.content : "";
      cancelText.value = params.cancelText ? params.cancelText : cancelText.value;
      confirmText.value = params.confirmText ? params.confirmText : confirmText.value;
    };
    var close = function close() {
      emits("close");
    };
    var confirm = function confirm() {
      emits("confirm");
    };
    expose({
      open: open,
      showModal: showModal
    });
    return function (_ctx, _cache) {
      var _component_n_modal = _resolveComponent("n-modal");
      return _openBlock(), _createBlock(_component_n_modal, {
        show: _unref(showModal),
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(showModal) ? showModal.value = $event : showModal = $event;
        })
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_unref(title)), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(content)), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
            class: "back-order flex-center mr10 pointer",
            onClick: close
          }, _toDisplayString(_unref(cancelText)), 1), _createElementVNode("div", {
            class: "confirm-pay flex-center ml10 pointer",
            onClick: confirm
          }, _toDisplayString(_unref(confirmText)), 1)])])];
        }),
        _: 1
      }, 8, ["show"]);
    };
  }
};